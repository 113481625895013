import React from 'react';
import { graphql } from 'gatsby';

import CategoryGrid from '../components/CategoryGrid';
import SEO from '../components/SEO';

function HomePage({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  return (
    <React.Fragment>
      <SEO pageTitle="Kokroze" />
      <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
        Kokroze
      </h1>

      <hr className="border-b border-gainsboro w-10" />

      <CategoryGrid
        categories={edges.filter(category => category.node.frontmatter.status === 'published').filter(category => category.node.frontmatter.url !== 'all')}
      />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query HomepageQuery {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(src/categories)/"}, frontmatter: { }},
      sort: { fields: [frontmatter___sort], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            url
            name
            status
            image {
              childImageSharp {
                fluid(maxWidth: 560) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
